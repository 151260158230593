<script>
import { mapActions } from 'vuex';
import { FALLBACK_MESSAGE, navigationErrorHandler } from '@emobg/web-utils';
import { ALERT_STATUS_NAMES } from '@domains/Carrental/Alerts/const/alertData.const';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import carrental from '../../router/CarrentalRouterMap';

export default {
  name: 'AlertLink',
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    alertName() {
      return this.result.alert_name || FALLBACK_MESSAGE.dash;
    },
  },
  created() {
    this.carrental = carrental;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carRental.alert.details, [
      'updateAlert',
    ]),
    async onClick() {
      if (this.result.status === ALERT_STATUS_NAMES.new) {
        await this.updateAlert({
          alertId: this.result.objectID,
          data: { status: ALERT_STATUS_NAMES.viewed },
        });
      }
      this.$router
        .push({
          name: carrental.vehicles.alerts.detail,
          params: {
            vehicleVin: this.result.vin,
            alertUuid: this.result.objectID,
          },
        })
        .catch(navigationErrorHandler);
    },
  },
};
</script>
<template>
  <a
    class="emobg-link-primary emobg-body-2"
    data-test-id="alert-link"
    @click="onClick"
  >
    {{ alertName }}
  </a>
</template>
