export const ALERT_TYPES = {
  collisionDetected: 'collision_detected',
  geofenceCrossed: 'geofence_crossed',
};

export const ALERT_CLASSES = {
  low: 'warning',
  moderate: 'moderate',
  medium: 'moderate',
  critical: 'danger',
  fallback: 'ground',
  new: 'danger',
  viewed: 'warning',
  closed: 'ground',
};

export const ALERT_STATUS_NAMES = {
  new: 'NEW',
  viewed: 'VIEWED',
  closed: 'CLOSED',
};

export const ALERT_TRIGGERS_LABELS = {
  in: 'Enters the zone',
  out: 'Leaves the zone',
};

export const ALERT_DETAILS_LABELS = {
  position: 'location',
};

export const ALERT_FILTERS_NAME = {
  occurredOnStartDate: 'occurredOnStartDate',
  occurredOnEndDate: 'occurredOnEndDate',
};

export const OPERATORS = {
  greaterOrEqual: '>=',
  lowerOrEqual: '<=',
};
