var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "emobg-link-primary emobg-body-2",
      attrs: { "data-test-id": "alert-link" },
      on: { click: _vm.onClick },
    },
    [_vm._v(" " + _vm._s(_vm.alertName) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }