<script>
import get from 'lodash/get';
import concat from 'lodash/concat';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import map from 'lodash/map';
import omit from 'lodash/omit';

import { jsPDF as JsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  DATE_FORMAT,
  DELAY,
  formatUtc,
  navigationErrorHandler,
  sentenceCase,
  TIME_ZONE,
} from '@emobg/web-utils';
import permissionsMixin from '@domains/Main/mixins/permissionsMixin';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { CommentsComponent, GoogleMapZonesComponent } from '@/components';
import AlertDetailsComponent from './components/AlertDetailsComponent';
import CollisionMapAndChart from './components/CollisionMapAndChart';
import CollisionReportPdfTemplate from './components/CollisionReportPdfTemplate';
import carrentalRouter from '../../router/CarrentalRouterMap';
import { CARRENTAL_PERMISSIONS } from '../../const/permissions';
import { CARRENTAL_FLAGS } from '../../const/features';
import { mileageUnitMixin } from '../../mixins';
import { ALERT_CLASSES, ALERT_STATUS_NAMES, ALERT_TYPES } from '../const/alertData.const';
import { COLLISION_REPORT_PDF_ID } from './const/collision.const';
import { VEHICLE_GPS_LINE_COLOR, VEHICLE_LINE_ZOOM } from './const/vehicleGps.const';
import { getLocationLabel } from '../utils/location';

export default {
  name: 'AlertsDetailsView',
  components: {
    AlertDetailsComponent,
    CommentsComponent,
    GoogleMapZonesComponent,
    CollisionMapAndChart,
    CollisionReportPdfTemplate,
  },
  mixins: [permissionsMixin, mileageUnitMixin],
  data() {
    return {
      alertPayload: {
        vehicleVin: get(this, '$route.params.vehicleVin'),
        alertUuid: get(this, '$route.params.alertUuid'),
      },
      isDownloading: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carRental.alert.details, {
      alertData: state => state.alertData.data || {},
      alertDataStatus: state => state.alertData.STATUS,
      commentsData: state => concat(state.comments.data),
      areCommentsLoading: state => state.comments.STATUS.LOADING,
      isChangingAlertStatus: state => state.STATUS.LOADING,
      isAlertDetailsLoading: state => state.alertData.STATUS.LOADING,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userAccountData: state => get(state, 'user.data', {}),
      features: state => get(state, 'features.data'),
      author: state => get(state, 'user.data.uuid'),
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    ...mapGetters(DOMAINS_MODEL.carRental.alert.details, [
      'alertDetails',
      'alertVehicleData',
      'vehicleMovementData',
    ]),
    ocurredOnTimestamp() {
      return moment(this.alertData.occurredOn).format('X');
    },
    alertVehicleRoute() {
      const geofenceRoute = get(this.alertDetails, 'map.route', []);
      const routeCoordinates = map(geofenceRoute, route => omit(route, 'occurredOn'));
      return !isEmpty(routeCoordinates)
        ? {
          color: VEHICLE_GPS_LINE_COLOR,
          polyline: routeCoordinates,
          tooltip: this.getTooltipTemplate(),
        }
        : {};
    },
    isAlertClosed() {
      return this.alertData.status === ALERT_STATUS_NAMES.closed;
    },
    hasMapData() {
      const hasGeofences = !isEmpty(get(this.alertDetails, 'map.geofences', []));
      return toLower(this.alertData.type) === ALERT_TYPES.geofenceCrossed && hasGeofences;
    },
    isCollisionReportEnabled() {
      return toLower(this.alertData.type) === ALERT_TYPES.collisionDetected
      && this.features[this.CARRENTAL_FLAGS.collisionReport];
    },
  },
  async mounted() {
    await this.getAlertData({
      alertUuid: this.alertPayload.alertUuid,
      data: {
        vin: this.alertPayload.vehicleVin,
      },
    });
    if (!this.alertDataStatus.ERROR) {
      await this.getComments(this.alertPayload);
    }
    if (!isEmpty(this.alertVehicleRoute)) {
      const bounds = new this.googleInstance.maps.LatLngBounds();
      const routeGps = get(this.alertDetails, 'map.route');
      const geofences = get(this.alertDetails, 'map.geofences');
      routeGps.forEach(gps => bounds.extend({ lat: gps.latitude, lng: gps.longitude }));
      const center = bounds.getCenter();
      this.$refs.alertGmap.setCenterAndZoom(center, VEHICLE_LINE_ZOOM);
      // Hide info window on geofences due to we show the info window in the center of the zone and if we have the gps of the vehicle we dont want it
      /* eslint-disable no-return-assign, no-param-reassign */
      geofences.forEach(geofence => geofence.showInfoWindow = false);
    }
  },
  created() {
    this.clearData();
    this.CARRENTAL_PERMISSIONS = CARRENTAL_PERMISSIONS;
    this.CARRENTAL_FLAGS = CARRENTAL_FLAGS;
    this.DATE_FORMAT = DATE_FORMAT;
    this.TIME_ZONE = TIME_ZONE;
    this.carrentalRouter = carrentalRouter;
    this.googleInstance = this.$GoogleMaps.getInstance();
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carRental.alert.details, [
      'postComment',
      'deleteComment',
      'getAlertData',
      'getComments',
      'updateAlert',
    ]),
    ...mapMutations(DOMAINS_MODEL.carRental.alert.details, ['clearData']),
    ...mapMutations(DOMAINS_MODEL.app.messages.errors, [
      'throwError',
    ]),
    badgeClass() {
      return ALERT_CLASSES[toLower(this.alertData.status)];
    },
    async updateAlertStatus() {
      if (this.isAlertClosed) {
        await this.updateAlert({
          alertId: this.alertPayload.alertUuid,
          data: { status: ALERT_STATUS_NAMES.new },
        });
      } else {
        await this.updateAlert({
          alertId: this.alertPayload.alertUuid,
          data: { status: ALERT_STATUS_NAMES.closed },
        });
        this.throwError({ message: 'Alert closed, please click inside to reopen it again' });
      }
      this.$router.push({ name: this.carrentalRouter.vehicles.alerts.index }).catch(navigationErrorHandler);
    },
    getAlertColor() {
      return ALERT_CLASSES[toLower(this.alertData.severity)] || ALERT_CLASSES.fallback;
    },
    doDownloadPdf() {
      this.isDownloading = true;
      const element = document.getElementById(COLLISION_REPORT_PDF_ID);
      const filename = `Collision report for ${this.alertPayload.alertUuid} at ${formatUtc(this.alertData.occurredOn)}`;
      this.$notify({
        message: 'The collision report export has started. This process might take a while.',
        delay: DELAY.extraLong,
        type: NOTIFICATION_TYPES.info,
      });

      html2canvas(element, { useCORS: true })
        .then(canvas => {
          const pageCanvas = canvas.toDataURL('image/png');
          const pdf = new JsPDF('p', 'mm', 'a4');

          const { height, width } = canvas;
          const widthRatio = pdf.internal.pageSize.getWidth() / width;
          const heightRatio = pdf.internal.pageSize.getHeight() / height;

          const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

          pdf.addImage(pageCanvas, 'PNG', 0, 0, width * ratio, height * ratio);
          pdf.save(filename);
          this.isDownloading = false;
        });
    },
    getContent(body) {
      // TODO: remove entire user state mapping after
      // https://ubeeqo.atlassian.net/browse/CCP-80 is done
      /* eslint camelcase: 0 */
      const {
        uuid,
        first_name,
        last_name,
      } = this.userAccountData;

      return {
        ...this.alertPayload,
        content: {
          body,
          reporter: {
            id: uuid,
            firstName: first_name,
            lastName: last_name,
          },
        },
      };
    },
    buildPayload(body) {
      this.postComment(this.getContent(body));
    },
    remove(commentId) {
      this.deleteComment({ ...this.alertPayload, commentId, userId: this.userAccountData.uuid });
    },
    getTooltipTemplate() {
      return `<p>Known vehicle positions 10 minutes before and after the GPS crossing point.</p>
        <p>Alert occurred on: ${formatUtc(this.alertData.occurredOn, DATE_FORMAT.defaultExtended, this.operatorTimezone)}</p>`;
    },
    concat,
    isEmpty,
    includes,
    get,
    formatUtc,
    sentenceCase,
    getLocationLabel,
  },
};
</script>

<template>
  <div
    data-test-id="alert_details-view"
    class="AlertsDetailsView position-relative emobg-background-color-ground-lighter py-3 px-2 px-lg-5"
  >
    <ui-loader v-if="isAlertDetailsLoading" />
    <template v-else>
      <h1 class="py-3 d-flex justify-content-between">
        <span>Alert Detail</span>
        <template v-if="alertData && includes(permissions, CARRENTAL_PERMISSIONS.alertsChangeStatus)">
          <ui-button
            v-if="!isAlertClosed"
            :face="FACES.outline"
            :loading="isChangingAlertStatus"
            data-test-id="close-button"
            @clickbutton="updateAlertStatus"
          >
            Close alert
          </ui-button>

          <ui-button
            v-if="isAlertClosed"
            :face="FACES.outline"
            :loading="isChangingAlertStatus"
            data-test-id="reopen-button"
            @clickbutton="updateAlertStatus"
          >
            Reopen alert
          </ui-button>
        </template>
      </h1>
      <div class="row emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-white mx-0">
        <div class="col-md-8 p-3 emobg-border-right-1 emobg-border-color-ground-light">
          <h2 class="my-2 pb-3 emobg-border-bottom-2 emobg-border-color-ground-lighter">
            {{ `${alertData.name} (${alertData.class})` }}
          </h2>
          <h3 class="pb-2 pt-4">
            Vehicle and movement details
          </h3>

          <div
            v-if="!isEmpty(alertData)"
            class="row no-gutters pt-1 pb-4 emobg-border-bottom-2 emobg-border-color-ground-lighter"
          >
            <div class="col-3">
              <div
                class="py-2 emobg-body-2"
                data-test-id="registration_number-label"
              >
                Registration number
              </div>
              <p class="mb-3">
                <RouterLink
                  :to="{
                    name: carrentalRouter.vehicles.summary.alerts,
                    params: {
                      vehicleVin: alertPayload.vehicleVin
                    }
                  }"
                  target="_blank"
                  data-test-id="license_plate-vehicle_summary_alerts-link"
                  class="emobg-link-primary emobg-body-2"
                >
                  {{ get(alertVehicleData, 'licensePlate') }}
                </RouterLink>
              </p>
              <div
                class="pb-1 emobg-body-2"
                data-test-id="vin_number-label"
              >
                Vin number
              </div>
              <div
                class="pt-1 mb-3"
                data-test-id="vin_number-value"
              >
                {{ alertPayload.vehicleVin }}
              </div>
              <div
                class="pb-1 emobg-body-2"
                data-test-id="model-label"
              >
                Model
              </div>
              <div
                class="pt-1"
                data-test-id="model-value"
              >
                {{ get(alertVehicleData, 'model') }}
              </div>
            </div>
            <div class="col-3">
              <div
                class="py-2 emobg-body-2"
                data-test-id="gwy_status-label"
              >
                GWY status
              </div>
              <div
                data-test-id="gwy_status-value"
                class="mb-3"
              >
                {{ alertVehicleData.greenwayStatus || FALLBACK_MESSAGE.noData }}
              </div>
              <div class="d-flex align-items-center">
                <div
                  class="py-2 emobg-body-2"
                  data-test-id="original_movement-label"
                >
                  Original movement
                </div>
                <ui-tooltip tooltip="Original movement: Is the movement of the vehicle when the alert was triggered">
                  <ui-icon
                    :color="GRAYSCALE.inkLight"
                    :size="ICONS_SIZES.small"
                    :icon="ICONS.infoFull"
                    class="ml-1"
                  />
                </ui-tooltip>
              </div>
              <div
                data-test-id="original_movement-value"
                class="mb-3"
              >
                {{ get(vehicleMovementData, 'type') || FALLBACK_MESSAGE.noData }}
              </div>
              <div class="d-flex align-items-center">
                <div
                  class="py-2 emobg-body-2"
                  data-test-id="updated_movement-label"
                >
                  Updated movement
                </div>
                <ui-tooltip tooltip="Updated movement: Is the latest movement of the vehicle that we have on the system">
                  <ui-icon
                    :color="GRAYSCALE.inkLight"
                    :size="ICONS_SIZES.small"
                    :icon="ICONS.infoFull"
                    class="ml-1"
                  />
                </ui-tooltip>
              </div>
              <div
                data-test-id="updated_movement-value"
                class="mb-3"
              >
                {{ get(vehicleMovementData, 'updatedType') || FALLBACK_MESSAGE.noData }}
              </div>
            </div>
            <div
              v-if="!isEmpty(vehicleMovementData)"
              class="col-3"
            >
              <div
                class="py-2 emobg-body-2"
                data-test-id="ra_number-label"
              >
                RA number
              </div>
              <div
                data-test-id="ra_number-value"
                class="mb-3"
              >
                {{ get(vehicleMovementData, 'rentalAgreementNumber') || FALLBACK_MESSAGE.noData }}
              </div>
              <div class="py-2 emobg-body-2">
                Pickup
              </div>
              <template v-if="!get(vehicleMovementData, 'startTime') && isEmpty(get(vehicleMovementData, 'startLocation', {}))">
                <div
                  data-test-id="pickup_no_data-label"
                  class="mb-3"
                >
                  {{ FALLBACK_MESSAGE.noData }}
                </div>
              </template>
              <template v-else>
                <div
                  v-if="get(vehicleMovementData, 'startTime')"
                  data-test-id="pickup_date"
                  class="mb-1"
                >
                  {{ formatUtc(vehicleMovementData.startTime, DATE_FORMAT.defaultExtended, operatorTimezone) }}
                </div>
                <div
                  data-test-id="pickup_station"
                  class="mb-3"
                >
                  {{ getLocationLabel(get(vehicleMovementData, 'startLocation')) }}
                </div>
              </template>
              <div class="py-2 emobg-body-2">
                Return
              </div>
              <template v-if="!get(vehicleMovementData, 'endTime') && isEmpty(get(vehicleMovementData, 'endLocation', {}))">
                <div
                  data-test-id="return_no_data-label"
                  class="mb-3"
                >
                  {{ FALLBACK_MESSAGE.noData }}
                </div>
              </template>
              <template v-else>
                <div
                  data-test-id="return_date"
                  class="mb-1"
                >
                  {{ formatUtc(vehicleMovementData.endTime, DATE_FORMAT.defaultExtended, operatorTimezone) }}
                </div>
                <div
                  data-test-id="return_station"
                  class="mb-3"
                >
                  {{ getLocationLabel(get(vehicleMovementData, 'endLocation')) }}
                </div>
              </template>
            </div>
          </div>
          <div class="row mb-4">
            <h3 class="mt-4 pb-3 col-12">
              Alert details
            </h3>
            <div class="col-3">
              <div
                class="pt-2 pb-1 emobg-body-2"
                data-test-id="alert_status-label"
              >
                Alert status
              </div>
              <div
                class="pt-1 mb-3"
                data-test-id="alert_status-value"
              >
                <ui-badge :color="badgeClass()">
                  {{ sentenceCase(alertData.status || FALLBACK_MESSAGE.noData) }}
                </ui-badge>
              </div>
              <div
                class="pb-1 emobg-body-2"
                data-test-id="alert_severity-label"
              >
                Alert severity
              </div>
              <div
                data-test-id="alert_severity-value"
                class="pt-1"
              >
                <ui-badge :color="getAlertColor()">
                  {{ sentenceCase(alertData.severity || FALLBACK_MESSAGE.noData) }}
                </ui-badge>
              </div>
              <template v-if="isCollisionReportEnabled">
                <div class="mt-3 pb-1 emobg-body-2">
                  Collision report
                </div>
                <ui-button
                  :face="FACES.outline"
                  :loading="isDownloading"
                  @clickbutton="doDownloadPdf"
                >
                  <ui-icon
                    :color="COLORS.primary"
                    :size="ICONS_SIZES.small"
                    :icon="ICONS.download"
                    class="mr-1"
                  />
                  <span>Export results</span>
                </ui-button>
              </template>
            </div>
            <div class="col-3">
              <div
                class="pt-2 pb-1 emobg-body-2"
                data-test-id="alert_occurred_on-label"
              >
                Alert occurred on
              </div>
              <div
                data-test-id="alert_occurred_on-value"
                class="pt-1 mb-3"
              >
                {{ formatUtc(alertData.occurredOn, DATE_FORMAT.defaultExtended, operatorTimezone) }}
              </div>
              <template v-if="alertDetails.mileage">
                <div
                  class="pt-2 pb-1 emobg-body-2"
                  data-test-id="mileage_at_the_collision-label"
                >
                  Mileage at the collision
                </div>
                <div
                  data-test-id="mileage_at_the_collision-value"
                  class="pt-1 mb-3"
                >
                  {{ formatDistance(alertDetails.mileage, mileageUnit, 0) }}
                </div>
              </template>
            </div>
            <div class="col-3">
              <AlertDetailsComponent :alert-details="alertDetails" />
            </div>
          </div>
          <div
            v-if="hasMapData"
            class="row"
          >
            <h3 class="col-12">
              Map
            </h3>
            <div class="col-12 mt-2">
              <GoogleMapZonesComponent
                ref="alertGmap"
                data-test-id="map"
                :map-type-control="false"
                :zones="concat(alertDetails.map.geofences, alertVehicleRoute)"
                :street-view-control="false"
                full-screen-control
                is-static
                class="mt-2 emobg-border-1 emobg-border-color-none"
              />
            </div>
            <span class="col-12 mt-2 emobg-color-ink-light">Showing known Vehicle Positions 10 minutes before and after the GPS crossing point</span>
          </div>
          <div
            v-if="isCollisionReportEnabled"
            class="row"
          >
            <CollisionMapAndChart :timestamp="ocurredOnTimestamp" />
          </div>
        </div>

        <div class="col-md-4 p-3 emobg-background-color-ground-lightest">
          <h2 class="mt-2 mb-5 pb-3 emobg-border-bottom-2 emobg-border-color-ground-light">
            Comments
          </h2>
          <CommentsComponent
            :can-comment="includes(permissions, CARRENTAL_PERMISSIONS.alertsComment)"
            :can-edit="false"
            :comments="commentsData"
            :is-loading="areCommentsLoading"
            :author="author"
            data-test-id="comments"
            @addComment="buildPayload"
            @deleteComment="remove"
          />
        </div>
      </div>
      <div
        v-if="isCollisionReportEnabled"
        class="AlertsDetailsView__pdfWrapper position-absolute"
      >
        <CollisionReportPdfTemplate :timestamp="ocurredOnTimestamp" />
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.AlertsDetailsView {
  &__pdfWrapper {
    top: 0;
    z-index: -10;
  }
}
</style>
